import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import classNames from "classnames"

import Layout from "./layout"
// import Image from "../components/image"
import SEO from "./seo"
import st from "./css/post_layout.module.scss"

const aboutMenu = [
  {
    link: "/aboutus.html",
    text: "关于我们",
  },
  {
    link: "/joinus.html",
    text: "加入我们",
  },
  {
    link: "/contactus.html",
    text: "联系我们",
  },
  {
    link: "/agreement.html",
    text: "酷课服务协议",
  },
  {
    link: "/promise.html",
    text: "酷课服务承诺",
  },
]

const PostTemplate = function ({ data: { markdownRemark: post } }) {
  // console.log(post)
  // console.log(rest)
  // console.log(all)
  // console.log(permalink)
  const { title, permalink, excerpt } = post.frontmatter
  return (
    <Layout cur={permalink} showSBar bg="#f4f4f4">
      <SEO
        title="酷课约课系统团队介绍"
        keywords={[
          "酷课团队",
          "约课系统开发",
          "培训机构解决方案",
          "微信小程序开发",
          "会员管理系统开发",
          "约课系统服务",
          "武汉酷美科技"
        ]}
        description="酷课约课系统由武汉酷美科技有限公司开发运营，团队由资深互联网技术专家和培训行业专家组成，致力于为培训机构提供专业的约课管理解决方案。"
      />

      <div className={st.wraper}>
        <div className="clearfix">
          <article className={st.main} style={{ fontSize: 14 }}>
            <header>
              <h1>{title}</h1>
            </header>

            {excerpt && <section className={st.excerpt}>{excerpt}</section>}

            <section
              className={st.content}
              dangerouslySetInnerHTML={{ __html: post.html }}
            ></section>
          </article>

          <aside className={st.asideWrap} style={{ top: 15 }}>
            {aboutMenu.map(v => (
              <div
                className={classNames(st.aboutMenu, {
                  [st.cur]: permalink === v.link,
                })}
              >
                <Link to={v.link}>{v.text}</Link>
              </div>
            ))}
          </aside>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogPostBySlugAndBlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        category
        tags
        permalink
        excerpt
      }
    }
  }
`

export default PostTemplate
